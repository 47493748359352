import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    pin: number = null;
    validation: any = {};
    saving: boolean = false;
    translationsLoading: boolean = false;

    constructor(
        private AuthenticationService: AuthenticationService,
        private ConfigService: ConfigService
    ) {}

    ngOnInit(): void {
        this.ConfigService.getTranslationsLoading().subscribe((next) => {
            this.translationsLoading = next;
        });
    }

    login() {
        this.saving = true;
        this.AuthenticationService.login(this.pin).subscribe({
            next: (next) => {
                setTimeout(() => {
                    window.location.href = '/';
                }, 200);
            },
            error: (error) => {
                this.validation = error.error.details;
                this.saving = false;
            }
        });
    }
}
