<div class="container page">

    <ng-container *ngIf="ready && !completed">

        <div class="progress-count">
            <ng-container *ngFor="let question of survey.questions; index as $i">
                <div class="progress-count-item" [class.active]="this.activeIndex == $i" [class.completed]="this.activeIndex > $i">{{$i + 1}}</div>
            </ng-container>
        </div>

    </ng-container>

    <div class="speech-bubble">
        
        <ng-container *ngIf="!ready"></ng-container>

        <ng-container *ngIf="ready">

            <ng-container *ngFor="let question of survey.questions; index as $i">
                <div class="slide-panel" [@slideInOut] [@.disabled]="!animate" *ngIf="this.activeIndex == $i && !completed">
                    <h2>{{'surveydetail_question' | translate}} {{activeIndex + 1}}/{{survey.questions.length}}</h2>
                    <div class="descr">{{question[language]}}</div>
                    <div class="form-wrap">
                        <ng-container *ngIf="question.type === 'rating'">
                            <div class="answer answer--rating">
                                <img (click)="question.answer = 1" [class.active]="question.answer == 1" src="/assets/img/rating/1.svg" alt="1">
                                <img (click)="question.answer = 2" [class.active]="question.answer == 2" src="/assets/img/rating/2.svg" alt="2">
                                <img (click)="question.answer = 3" [class.active]="question.answer == 3" src="/assets/img/rating/3.svg" alt="3">
                                <img (click)="question.answer = 4" [class.active]="question.answer == 4" src="/assets/img/rating/4.svg" alt="4">
                                <img (click)="question.answer = 5" [class.active]="question.answer == 5" src="/assets/img/rating/5.svg" alt="5">
                            </div>
                        </ng-container>
                        <ng-container *ngIf="question.type === 'text'">
                            <div class="answer answer--text">
                                <textarea [(ngModel)]="question.answer" [placeholder]="'surveydetail_answer_text_placeholder' | translate" rows="4"></textarea>
                            </div>
                        </ng-container>
                        <button [class.disabled]="(!question.answer && question.required) || saving || this.activeIndex != $i" (click)="(question.answer || !question.required) && next()" class="button default">{{'surveydetail_next' | translate}}</button>
                    </div>
                </div>
            </ng-container>
            <div class="slide-panel slide-panel--thanks" [@slideInOut] [@.disabled]="!animate" *ngIf="completed">
                <h1 class="secondary-color">{{'surveydetail_thanks_title' | translate}}</h1>
                <h2>{{'surveydetail_thanks_subtitle' | translate}}</h2>
                <div class="title-sep secondary-background"></div>
                <div class="descr">{{'surveydetail_descr_thanks' | translate}}</div>
                <a *ngIf="user" [routerLink]="['/survey']" class="button light">{{'surveydetail_next_thanks' | translate}}</a>
            </div>

        </ng-container>

    </div>
</div>