import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-field-horizontal-radio-simple',
    templateUrl: './field-horizontal-radio-simple.component.html',
    styleUrls: ['./field-horizontal-radio-simple.component.scss']
})
export class FieldHorizontalRadioSimpleComponent implements OnInit {
    @Input() options: [{ title: string; value: any }];
    @Input() optionWidth: number;
    @Input() optionColumnWidth?: string;
    @Input() fillWidth?: boolean;
    @Input() canUncheck?: boolean;
    @Input() readOnly?: boolean;
    @Input() model: any;
    @Input() label?: string;
    @Input() error?: string;
    @Input() optionalString?: string;
    @Output() modelChange = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    emit($event) {
        if (this.readOnly) {
            return;
        }
        this.modelChange.emit($event);
    }
}
