<div class="container">
    <header class="d-flex justify-content-between">
        <div class="language-switch d-flex align-items-center">
            <div [class.active]="language == 'nl'" (click)="TranslatorService.setLanguage('nl')">NL</div>
            <div [class.active]="language == 'fr'" (click)="TranslatorService.setLanguage('fr')">FR</div>
        </div>

        <ng-container *ngIf="user">
            <div *ngIf="showLogout" (click)="logout()" [class.saving]="saving" class="logout d-flex align-items-center saving--pre">{{'logout' | translate}}</div>
            <a *ngIf="showBackToHome" [routerLink]="['/']" class="back-to-home d-flex align-items-center">
                <i class="icon icon-home"></i>
                <span>{{'header_back_to_home' | translate}}</span>
            </a>
        </ng-container>
    </header>
</div>