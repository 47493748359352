import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiModule, Configuration, ConfigurationParameters } from './utils/api';
import { ClickOutsideDirective } from './utils/directives';
import {
    displayPipe,
    listPipe,
    callbackPipe,
    formatNumberPipe,
    httpsPipe,
    SafePipe,
    toFixedPipe,
    vatPipe,
    customCurrencyPipe,
    percentagePipe,
    TranslatePipe,
    numberPipe
} from './utils/pipes';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { SpinnerComponent } from './components/misc/spinner/spinner.component';
import { FieldTextComponent } from './components/fields/field-text/field-text.component';
import { FieldSelectComponent } from './components/fields/field-select/field-select.component';
import { InitService } from './services/init.service';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { SurveyComponent } from './pages/survey/survey.component';
import { SurveyDetailComponent } from './components/survey-detail/survey-detail.component';
import { FieldHorizontalRadioComponent } from './components/fields/field-horizontal-radio/field-horizontal-radio.component';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FieldDateComponent } from './components/fields/field-date/field-date.component';
import { LineChartComponent } from './components/charts/line-chart/line-chart.component';
import { HorizontalBarChartComponent } from './components/charts/horizontal-bar-chart/horizontal-bar-chart.component';
import { CombinedVerticalBarChartComponent } from './components/charts/combined-vertical-bar-chart/combined-vertical-bar-chart.component';
import { FieldTypeaheadComponent } from './components/fields/field-typeahead/field-typeahead.component';
import { FieldHorizontalRadioSimpleComponent } from './components/fields/field-horizontal-radio-simple/field-horizontal-radio-simple.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        AppComponent,
        ClickOutsideDirective,
        displayPipe,
        listPipe,
        callbackPipe,
        formatNumberPipe,
        httpsPipe,
        SafePipe,
        toFixedPipe,
        vatPipe,
        TranslatePipe,
        customCurrencyPipe,
        percentagePipe,
        numberPipe,
        HeaderComponent,
        FooterComponent,
        SpinnerComponent,
        FieldTextComponent,
        FieldSelectComponent,
        LoginComponent,
        DashboardComponent,
        HomeComponent,
        SurveyComponent,
        SurveyDetailComponent,
        FieldHorizontalRadioComponent,
        FieldDateComponent,
        LineChartComponent,
        HorizontalBarChartComponent,
        CombinedVerticalBarChartComponent,
        FieldTypeaheadComponent,
        FieldHorizontalRadioSimpleComponent
    ],
    imports: [
        ToastrModule.forRoot({
            timeOut: 3000,
            extendedTimeOut: 3000,
            positionClass: 'toast-bottom-center',
            closeButton: false,
            disableTimeOut: false,
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning'
            }
        }),
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule.forRoot(() => {
            return new Configuration({ basePath: '/api' });
        })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: initializeApp, multi: true, deps: [InitService] },
        provideNgxMask(options),
        CurrencyPipe,
        customCurrencyPipe,
        percentagePipe,
        TranslatePipe,
        numberPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function initializeApp(InitService: InitService) {
    return (): Promise<any> => {
        return InitService.initialize();
    };
}
