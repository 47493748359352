import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    translationsLoading$ = new BehaviorSubject<boolean>(false);

    constructor(private Router: Router, private DefaultService: DefaultService) {}

    public setTranslationsLoading(value: boolean) {
        this.translationsLoading$.next(value);
    }
    public getTranslationsLoading() {
        return this.translationsLoading$;
    }
}
