import { Component, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ResourceService } from './services/resource.service';
import { HelpersService } from './services/helpers.service';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { TranslatePipe } from './utils/pipes';
import { ConfigService } from './services/config.service';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { AuthenticationService } from './services/authentication.service';
import { TranslatorService } from './services/translator.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    ready$ = new BehaviorSubject<boolean>(false);
    fetchedInitialRouteData: boolean = false;
    isPublic$ = new BehaviorSubject<boolean>(false);
    hidePrice: boolean = true;
    translationsLoading: boolean = false;

    constructor(
        public ConfigService: ConfigService,
        private TitleService: Title,
        private AuthenticationService: AuthenticationService,
        private TranslatorService: TranslatorService,
        private ResourceService: ResourceService,
        public Router: Router,
        public Route: ActivatedRoute,
        private HelpersService: HelpersService
    ) {}

    ngOnInit(): void {
        this.ConfigService.getTranslationsLoading().subscribe((next) => {
            this.translationsLoading = next;
        });
        // listen to router events
        this.Router.events.subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                let route = event.state.root.firstChild;
                this.HelpersService.setCurrentRoute(route);
                if (route && route.data && route.data.isPublic) {
                    this.isPublic$.next(true);
                } else {
                    this.isPublic$.next(false);
                }

                if (this.fetchedInitialRouteData) {
                    return;
                }

                // if (!route || !route.data.isPublic) {
                this.fetchedInitialRouteData = true;
                this.AuthenticationService.fetchUser().subscribe({
                    next: (next) => {
                        this.initPrivateRoute(next.language);
                    },
                    error: (error) => {
                        this.initPrivateRoute('nl');
                        if (!route.data.isPublic) {
                            this.AuthenticationService.onUnauth();
                        }
                    }
                });
            }
        });
    }

    public initPrivateRoute(language?) {
        const observables$: Observable<any>[] = [];
        observables$.push(...this.ResourceService.init());
        observables$.push(this.TranslatorService.init(language));
        forkJoin(observables$).subscribe((next) => {
            this.setReady(true);
        });
    }

    public getReady() {
        return this.ready$.asObservable();
    }

    private setReady(ready: boolean) {
        this.ready$.next(ready);
    }
}
