import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { TranslatorService } from 'src/app/services/translator.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    language: string = null;
    showBackToHome: boolean = false;
    showLogout: boolean = false;
    saving: boolean = false;
    user: any = null;

    constructor(
        public TranslatorService: TranslatorService,
        private Route: ActivatedRoute,
        private Router: Router,
        public HelpersService: HelpersService,
        public AuthenticationService: AuthenticationService
    ) {
        this.HelpersService.getCurrentRoute().subscribe((next) => {
            if (!next?.data?.showNav) {
                this.showBackToHome = false;
            } else this.showBackToHome = true;
            this.showLogout = next?.data?.isHome;
        });

        this.AuthenticationService.getUser().subscribe((next) => {
            this.user = next;
        });
    }

    ngOnInit(): void {
        this.TranslatorService.getLanguage().subscribe((next) => {
            this.language = next;
        });
    }

    logout() {
        this.saving = true;
        this.AuthenticationService.logout();
    }
}
