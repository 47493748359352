<div class="field field-text">
    <span *ngIf="label" class="label">{{label}} <span *ngIf="optionalString">({{optionalString}})</span></span>
    <div class="d-flex align-items-center w-100 position-relative" [class.disabled-wrap]="disabled">
        <input 
            *ngIf="code != 'pin' && !autofocus"
            [class.error-field]="error"
            [type]="getType()"
            class="flex-grow-1"
            [(ngModel)]="localModel" 
            (ngModelChange)="changes()"
            (blur)="onBlur($event)"
            (focus)="emitFocus.emit()"
            (keyup.enter)="onBlur(null); enter.emit()"
            [placeholder]="placeholder"
            placeHolderCharacter="_"
            [mask]="mask"
            [showMaskTyped]="true"
        />
        <input 
            *ngIf="code != 'pin' && autofocus"
            [class.error-field]="error"
            [type]="getType()"
            class="flex-grow-1"
            [(ngModel)]="localModel" 
            (ngModelChange)="changes()"
            (blur)="onBlur($event)"
            (focus)="emitFocus.emit()"
            (keyup.enter)="onBlur(null); enter.emit()"
            [placeholder]="placeholder"
            placeHolderCharacter="_"
            [mask]="mask"
            [showMaskTyped]="true"
            autofocus
        />
        <input 
            autofocus
            *ngIf="code == 'pin'"
            [class.error-field]="error"
            type="password" 
            pattern="[0-9]*" 
            inputmode="numeric"
            class="flex-grow-1"
            [(ngModel)]="localModel" 
            (ngModelChange)="changes()"
            (blur)="onBlur($event)"
            (focus)="emitFocus.emit()"
            (keyup.enter)="onBlur(null); enter.emit()"
            [placeholder]="placeholder"
        />
    </div>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>