import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    currentRoute$ = new BehaviorSubject<any>(null);

    public toTop(anchor?: string) {
        if (!anchor) {
            window.scrollTo(0, 0);
        } else {
            const el = document.getElementById(anchor);
            if (el) {
                const top = el.getBoundingClientRect().top + window.pageYOffset - 24;
                // window.scrollTo(0, top);
                window.scrollTo({ top: top, behavior: 'smooth' });
            } else {
                window.scrollTo(0, 0);
            }
        }
    }

    public setCurrentRoute(route: any) {
        this.currentRoute$.next(route);
    }

    public getCurrentRoute() {
        return this.currentRoute$.asObservable();
    }

    public objectIsEmpty(object: any) {
        if (object.constructor != Object) return true;
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                if (object[key] === null || object[key] === undefined || object[key] === '') {
                    continue;
                } else return false;
            }
        }
        return true;
    }
}
