import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { TranslatePipe } from '../utils/pipes';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    constructor(
        private DefaultService: DefaultService,
        private TranslatePipe: TranslatePipe,
        private AuthenticationService: AuthenticationService
    ) {}
    private dealers;

    public init(): Observable<any>[] {
        const observables$: Observable<any>[] = [of(null)];
        const user: any = this.AuthenticationService.getUserValue();
        if (user?.isAdmin) {
            const dealers$ = this.DefaultService.dealersGetDealers('').pipe(
                tap((next: any) => {
                    this.dealers = next.map((item) => {
                        return {
                            title: item.name,
                            value: item.id
                        };
                    });
                })
            );
            observables$.push(dealers$);
        }

        return observables$;
    }

    public getDealers() {
        return this.dealers;
    }

    // public getResource(type: string) {
    //     const result = this.resources$.getValue().filter((item) => {
    //         return item.type === type;
    //     });
    //     if (!result?.length) return [{ title: 'notfound', value: 'notfound' }];
    //     return result;
    // }

    // public getResourceById(type: string, id: string) {
    //     const result = this.resources$.getValue().find((item) => {
    //         return item.type === type && item.id === id;
    //     });
    //     return result || { title: 'notfound', value: 'notfound' };
    // }
}
