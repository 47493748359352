import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SurveyDetailComponent } from './components/survey-detail/survey-detail.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { SurveyComponent } from './pages/survey/survey.component';

export const routes: Routes = [
    // login
    { path: 'login', component: LoginComponent, data: { isPublic: true } },
    // home
    {
        path: '',
        component: HomeComponent,
        data: { isHome: true }
    },
    // dashboard
    {
        path: 'dashboard',
        component: DashboardComponent,
        data: { showNav: true }
    },
    // survey
    {
        path: 'survey',
        component: SurveyComponent,
        data: { showNav: true }
    },
    // survey detail
    {
        path: ':tinyId',
        component: SurveyDetailComponent,
        data: { isPublic: true, showNav: true }
    },
    // 404
    { path: '**', redirectTo: '/' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
