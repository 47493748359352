import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { TranslatorService } from 'src/app/services/translator.service';
import { TranslatePipe } from 'src/app/utils/pipes';
import { DefaultService } from 'src/app/utils/api';
import { ConfigService } from 'src/app/services/config.service';
import { ResourceService } from 'src/app/services/resource.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    SEARCH: any = {};
    period$ = new BehaviorSubject<any>(null);
    period: any = null;

    select_top_bottom: 'top' | 'bottom' = 'top';
    select_dealer: any = null;

    language: string = null;
    user: any = null;
    isAdmin: boolean = false;

    ready: boolean = false;
    loading: boolean = true;

    options: any = {};

    STATS: any = null;

    adminMode: 'group' | 'dealer' = 'group';

    translationsLoading: boolean = false;
    initialTranslationsLoading: boolean = true;

    disableSinceStart: string = null;
    disableUntilStart: string = null;
    disableSinceEnd: string = null;
    disableUntilEnd: string = null;

    ratingQuestionsData: any[] = [];
    textQuestionsData: any[] = [];
    allQuestionsData: any = {
        type: 'bar',
        labels: [],
        datasets: [],
        options: {
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    title: {
                        display: false,
                        text: ''
                    },
                    min: 1,
                    max: 5,
                    ticks: {
                        stepSize: 1
                    }
                },
                y: {
                    title: {
                        display: false,
                        text: ''
                    },

                    grid: {
                        display: false
                    }
                }
            },
            plugins: {
                legend: {
                    display: true,
                    align: 'start',
                    position: 'bottom',
                    usePointStyle: true,
                    labels: {
                        boxWidth: 12,
                        boxHeight: 12
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            var value = tooltipItem.dataset.data[tooltipItem.dataIndex];
                            return `${tooltipItem.dataset.label}: ${value.toString().replace('.', ',')}`;
                        }
                    }
                }
            }
        }
    };

    lineChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        scales: {
            x: {
                title: {
                    display: false,
                    text: ''
                },
                grid: {
                    display: false
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 5,
                    maxRotation: 0
                }
            },
            y: {
                title: {
                    display: false,
                    text: ''
                },
                min: 1,
                max: 5.4,
                ticks: {
                    stepSize: 1,
                    beginAtZero: false,
                    min: 1,
                    max: 5,
                    maxRotation: 0,
                    callback: function (value, index, values) {
                        if (value !== 5.4) {
                            return value;
                        }
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                align: 'start',
                position: 'bottom',
                usePointStyle: true,
                labels: {
                    boxWidth: 12,
                    boxHeight: 12
                }
            },
            tooltip: {
                callbacks: {
                    title: function (tooltipItems, data) {
                        return tooltipItems[0].dataset.data[tooltipItems[0].dataIndex].title;
                    },
                    label: function (tooltipItem, data) {
                        var value = tooltipItem.dataset.data[tooltipItem.dataIndex].y;
                        return `${tooltipItem.dataset.label}: ${value.toString().replace('.', ',')}`;
                    }
                }
            }
        }
    };

    combinedVerticalBarChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        scales: {
            x: {
                title: {
                    display: false,
                    text: ''
                },
                grid: {
                    display: false
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 5,
                    maxRotation: 0
                    // minRotation: 30
                }
            },
            y: {
                title: {
                    display: false,
                    text: ''
                },
                min: 1,
                max: 5.4,
                ticks: {
                    stepSize: 1,
                    beginAtZero: false,
                    min: 1,
                    max: 5,
                    maxRotation: 0,
                    callback: function (value, index, values) {
                        if (value !== 5.4) {
                            return value;
                        }
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            annotation: {
                annotations: {}
            },
            tooltip: {
                callbacks: {
                    title: function (tooltipItems, data) {
                        return tooltipItems[0].dataset.data[tooltipItems[0].dataIndex].title;
                    },
                    label: function (tooltipItem, data) {
                        return tooltipItem.dataset.data[tooltipItem.dataIndex].label;
                    }
                }
            }
        }
    };

    constructor(
        private TranslatePipe: TranslatePipe,
        private TranslatorService: TranslatorService,
        private AuthenticationService: AuthenticationService,
        public HelpersService: HelpersService,
        private DefaultService: DefaultService,
        private ConfigService: ConfigService,
        private ResourceService: ResourceService
    ) {
        this.TranslatorService.getLanguage().subscribe((next) => {
            this.language = next;
        });
        this.AuthenticationService.getUser().subscribe((next) => {
            this.user = next;
            this.isAdmin = this.user.isAdmin;
        });
    }

    ngOnInit(): void {
        this.ConfigService.getTranslationsLoading().subscribe((next) => {
            this.translationsLoading = next;
            if (next) return;
            if (!this.initialTranslationsLoading && !this.isAdmin) {
                this.search();
            } else {
                this.initialTranslationsLoading = false;
            }
        });
        this.period$.subscribe((next: 'last_month' | 'last_year' | 'date_range') => {
            this.period = next;
            switch (next) {
                case 'last_month':
                    this.SEARCH.end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
                    this.SEARCH.start_date = moment(this.SEARCH.end_date)
                        .subtract(1, 'months')
                        .add(1, 'days')
                        .format('YYYY-MM-DD');
                    break;
                case 'last_year':
                    this.SEARCH.end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
                    this.SEARCH.start_date = moment(this.SEARCH.end_date)
                        .subtract(1, 'years')
                        .add(1, 'days')
                        .format('YYYY-MM-DD');
                    break;
                case 'date_range':
                    this.SEARCH.end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
                    this.SEARCH.start_date = moment(this.SEARCH.end_date)
                        .subtract(1, 'months')
                        .add(1, 'days')
                        .format('YYYY-MM-DD');
                    break;
            }
            this.search();
        });
        this.period$.next('last_month');
        this.options = {
            date_range: [
                {
                    title: this.TranslatePipe.transform('daterange_last_month'),
                    value: 'last_month'
                },
                {
                    title: this.TranslatePipe.transform('daterange_last_year'),
                    value: 'last_year'
                },
                {
                    title: this.TranslatePipe.transform('daterange_date_range'),
                    value: 'date_range'
                }
            ],
            select_top_bottom: [
                {
                    title: this.TranslatePipe.transform('option_top_5'),
                    value: 'top'
                },
                {
                    title: this.TranslatePipe.transform('option_bottom_5'),
                    value: 'bottom'
                }
            ],
            admin_mode: [
                {
                    title: this.TranslatePipe.transform('dashboard_switch_option_group'),
                    value: 'group'
                },
                {
                    title: this.TranslatePipe.transform('dashboard_switch_option_company'),
                    value: 'dealer'
                }
            ],
            dealer: []
        };
        if (this.isAdmin) {
            this.options.dealer = this.ResourceService.getDealers();
        }
    }

    selectTopBottom(val: 'top' | 'bottom') {
        this.select_top_bottom = val;
        this.search();
    }

    selectDealer(val: any) {
        if (val?.item?.value) {
            this.select_dealer = val.item.value;
            this.search();
        } else this.select_dealer = null;
    }

    setDisabledDates() {
        this.disableSinceEnd = moment().subtract(0, 'days').format('YYYY-MM-DD');
        this.disableSinceStart = moment(this.disableSinceEnd).subtract(1, 'weeks').format('YYYY-MM-DD');
        this.disableUntilEnd = moment(this.SEARCH.start_date).add(1, 'weeks').format('YYYY-MM-DD');
    }

    search() {
        if (!this.SEARCH.start_date || !this.SEARCH.end_date) {
            return;
        }
        this.setDisabledDates();
        if (this.HelpersService.objectIsEmpty(this.SEARCH)) return;
        this.loading = true;

        // ADMIN GROUP
        if (this.isAdmin && this.adminMode === 'group') {
            let dir: 'asc' | 'desc' = 'desc';
            if (this.select_top_bottom === 'top') dir = 'asc';
            this.DefaultService.resultsGetTopResults(dir, 5, this.SEARCH.start_date, this.SEARCH.end_date).subscribe(
                (next) => {
                    this.STATS = next;
                    if (!this.isAdmin) {
                        this.setAllQuestionsData();
                        this.setRatingQuestionsData();
                        this.setTextQuestionsData();
                    } else {
                        this.setRatingQuestionsDataAdmin();
                    }
                    setTimeout(() => {
                        this.ready = true;
                    }, 300);
                    this.loading = false;
                }
            );
        }

        // ADMIN DEALER
        if (this.isAdmin && this.adminMode === 'dealer') {
            if (!this.select_dealer) {
                this.ready = true;
                return;
            }
            this.DefaultService.resultsGetDealerResults(
                this.SEARCH.start_date,
                this.SEARCH.end_date,
                this.select_dealer
            ).subscribe((next) => {
                this.STATS = next;
                this.setAllQuestionsData();
                this.setRatingQuestionsData();
                this.setTextQuestionsData();
                this.ready = true;
                this.loading = false;
            });
        }

        // DEALER
        if (!this.isAdmin) {
            this.DefaultService.resultsGetDealerResults(this.SEARCH.start_date, this.SEARCH.end_date).subscribe(
                (next) => {
                    this.STATS = next;
                    this.setAllQuestionsData();
                    this.setRatingQuestionsData();
                    this.setTextQuestionsData();
                    this.ready = true;
                    this.loading = false;
                }
            );
        }
    }

    setAllQuestionsData() {
        const labels = [];
        const datasets = [
            {
                data: [],
                label: this.TranslatePipe.transform('dashboard_chart_your_score'),
                borderColor: '#E94E55',
                backgroundColor: '#E94E55',
                pointBackgroundColor: '#E94E55',
                pointBorderWidth: 0,
                pointBorderColor: '#E94E55'
            },
            {
                data: [],
                label: this.TranslatePipe.transform('dashboard_chart_average_score'),
                borderColor: '#b5b6bd',
                backgroundColor: '#b5b6bd',
                pointBackgroundColor: '#b5b6bd',
                pointBorderWidth: 0,
                pointBorderColor: '#b5b6bd'
            }
        ];
        for (const key in this.STATS.all) {
            const questionObj = this.STATS.all[key];
            const question = key;
            const words = question.split(' ');
            const labelARR = [];
            for (let i = 0; i < words.length; i++) {
                const word = words[i];
                const index = Math.floor(i / 5);
                if (!labelARR[index]) labelARR[index] = '';
                labelARR[index] += `${word} `;
            }
            labels.push(
                labelARR.map((item) => {
                    return item.trim();
                })
            );
            datasets[0].data.push(this.formatData(questionObj.dealer));
            datasets[1].data.push(this.formatData(questionObj.group));
        }
        this.allQuestionsData.labels = labels;
        this.allQuestionsData.datasets = datasets;
        const result = { ...this.allQuestionsData };
        this.allQuestionsData = result;
    }

    setRatingQuestionsData() {
        const result = [];
        for (const key in this.STATS.rating) {
            const dataARR = this.STATS.rating[key];
            const questionObj = {
                title: key,
                chart: null
            };
            const chart = {
                datasets: [],
                labels: [],
                options: this.lineChartOptions
            };
            const labels = [];
            const datasets = [
                {
                    data: [],
                    label: this.TranslatePipe.transform('dashboard_chart_your_score'),
                    borderColor: '#E94E55',
                    backgroundColor: '#E94E55',
                    pointBackgroundColor: '#E94E55',
                    pointBorderWidth: 0,
                    pointBorderColor: '#E94E55'
                },
                {
                    data: [],
                    label: this.TranslatePipe.transform('dashboard_chart_average_score'),
                    borderColor: '#b5b6bd',
                    backgroundColor: '#b5b6bd',
                    pointBackgroundColor: '#b5b6bd',
                    pointBorderWidth: 0,
                    pointBorderColor: '#b5b6bd'
                }
            ];

            for (let i = 0; i < dataARR.length; i++) {
                const dataPoint = dataARR[i];
                const title = `${moment(dataPoint.min).format('DD-MM-YYYY')} ${this.TranslatePipe.transform(
                    'misc_until'
                )} ${moment(dataPoint.max).format('DD-MM-YYYY')}`;
                datasets[0].data.push({ y: this.formatData(dataPoint.dealer), x: i, title: title });
                datasets[1].data.push({ y: this.formatData(dataPoint.group), x: i, title: title });
                labels.push(`${moment(dataPoint.min).format('DD-MM-YYYY')}`);
            }

            chart.datasets = datasets;
            chart.labels = labels;
            questionObj.chart = chart;
            result.push(questionObj);
        }

        this.ratingQuestionsData = result;
    }

    // ADMIN
    setRatingQuestionsDataAdmin() {
        const result = [];
        for (const key in this.STATS.data) {
            const dataObj = this.STATS.data[key];
            const questionObj = {
                title: key,
                chart: null
            };
            const chart = {
                datasets: [],
                labels: [],
                options: this.combinedVerticalBarChartOptions,
                mean: null
            };
            const labels = [];
            const datasets = [
                {
                    data: [],
                    borderColor: '#E94E55',
                    backgroundColor: '#E94E55',
                    // pointBackgroundColor: '#E94E55',
                    // pointBorderWidth: 0,
                    // pointBorderColor: '#E94E55'
                    maxBarThickness: 40
                }
                // {
                //     data: [],
                //     label: this.TranslatePipe.transform('dashboard_chart_average_score'),
                //     borderColor: '#b5b6bd',
                //     backgroundColor: '#b5b6bd',
                //     pointBackgroundColor: '#b5b6bd',
                //     pointBorderWidth: 0,
                //     pointBorderColor: '#b5b6bd'
                // }
            ];

            for (let i = 0; i < dataObj.data.length; i++) {
                const barPoint = dataObj.data[i];

                const words = barPoint.dealer.split(' ');
                const labelARR = [];
                for (let i = 0; i < words.length; i++) {
                    const word = words[i];
                    const index = Math.floor(i / 1);
                    if (!labelARR[index]) labelARR[index] = '';
                    labelARR[index] += `${word} `;
                }
                labels.push(
                    labelARR.map((item) => {
                        return item.trim();
                    })
                );
                // labels.push(barPoint.dealer);

                datasets[0].data.push({ x: i + 1, y: barPoint.value, title: barPoint.dealer, label: barPoint.value });
                // const title = `${moment(dataPoint.min).format('DD-MM-YYYY')} ${this.TranslatePipe.transform(
                //     'misc_until'
                // )} ${moment(dataPoint.max).format('DD-MM-YYYY')}`;
                // datasets[0].data.push({ y: this.formatData(dataPoint.dealer), x: i, title: title });
                // datasets[1].data.push({ y: this.formatData(dataPoint.group), x: i, title: title });
                // labels.push(`${moment(dataPoint.min).format('DD-MM-YYYY')}`);
            }

            chart.datasets = datasets;
            chart.labels = labels;
            const mean = dataObj.mean;
            if (dataObj.mean !== undefined) {
                chart.mean = {
                    type: 'line',
                    borderColor: '#40435D',
                    borderDash: [6, 6],
                    borderDashOffset: 0,
                    borderWidth: 2,
                    label: {
                        enabled: true,
                        content: (ctx) => `${this.TranslatePipe.transform('dashboard_average_mean_label')}`,
                        // `${this.TranslatePipe.transform('dashboard_average_mean_label')}: ${this.formatData(mean)}`,
                        position: 'end',
                        backgroundColor: 'transparent',
                        color: '#40435D',
                        yAdjust: 10,
                        xAdjust: 11,
                        fontSize: 6,
                        fontStyle: 'light'
                    },
                    scaleID: 'y',
                    value: (ctx) => this.formatData(mean)
                };
            }
            questionObj.chart = chart;
            result.push(questionObj);
        }
        this.ratingQuestionsData = result;
    }

    setTextQuestionsData() {
        const result = [];
        for (const key in this.STATS.text) {
            const answerARR = this.STATS.text[key];
            result.push({
                title: key,
                comments: answerARR.map((item) => {
                    return {
                        text: item
                    };
                })
            });
        }
        this.textQuestionsData = result;
    }

    formatData(num) {
        if (!num) return null;
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }
}
