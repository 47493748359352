<div class="field field-select field-typeahead position-relative" [class.valid]="localModel">
    <span *ngIf="label" class="label">{{label}} <span *ngIf="optionalString">({{optionalString}})</span></span>
    <div [class.disabled-wrap]="disabled">
        <div class="typeahead-wrap" [class.open]="isOpen()">
            <input id="typeahead-prevent-manual-entry_{{index}}"  
                type="text"
                autocomplete="off"
                placement="bottom-left"
                [class.error-field]="error"
                [placeholder]="placeholder"
                [(ngModel)]="localModel"
                (blur)="onBlur()"
                (focus)="!localModel && focus$.next($any($event).target.value)"
                (selectItem)="onSelect($event)"
                [ngbTypeahead]="search"
                [inputFormatter]="formatter"
                [resultFormatter]="formatter"
                [resultTemplate]="template"
                (change)="change($event)"
                autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                [editable]="editable" />
            <div class="fill"></div>
        </div>
    </div>
    <span *ngIf="error" class="error-field-display">{{error}}</span>

    <ng-template #template let-r="result" let-t="term">
        <div [title]="r?.title">
            <ngb-highlight [result]="r.title" [term]="t"></ngb-highlight>
        </div>
        <span class="typeahead-after" *ngIf="afterARR && afterARR.length">
            <ng-container *ngFor="let item of afterARR; index as $i">
                <ng-container *ngIf="r[item]">
                    <ng-container *ngIf="$i">- </ng-container>{{r[item]}}
                </ng-container>
            </ng-container>
        </span>
    </ng-template>
</div>