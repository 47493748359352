<div class="container page" *ngIf="!translationsLoading">
    <div class="speech-bubble">
        <h2>{{'dashboard_title' | translate}}</h2>

        <!-- ADMIN -->
        <ng-container *ngIf="isAdmin">
            <!-- <div class="switch" [class.disabled]="!ready">
                <div class="option" [class.active]="adminMode === 'group'" (click)="ready = false; ratingQuestionsData = []; textQuestionsData = []; adminMode = 'group'; this.select_dealer = null; this.search();">{{'dashboard_switch_option_group' | translate}}</div>
                <div class="option" [class.active]="adminMode === 'dealer'" (click)="ready = false; ratingQuestionsData = []; textQuestionsData = []; adminMode = 'dealer'; this.select_dealer = null; this.search();">{{'dashboard_switch_option_company' | translate}}</div>
            </div> -->
            <div class="admin-mode-wrap" [class.saving]="!ready">
                <app-field-horizontal-radio-simple 
                    [label]="'dashboard_admin_mode_label' | translate" 
                    [model]="adminMode" 
                    (modelChange)="ready = false; ratingQuestionsData = []; textQuestionsData = []; adminMode = $event; this.select_dealer = null; this.search();"
                    [options]="options.admin_mode"
                    [optionWidth]="">
                </app-field-horizontal-radio-simple>
            </div>
        </ng-container>

        <!-- spinner -->
        <ng-container *ngIf="!ready" >
            <app-spinner [centerVertically]="false"></app-spinner>
        </ng-container>

        <ng-container *ngIf="ready">
            <div class="content">
                <div class="search-wrap">
                    <!-- ADMIN GROUP -->
                    <div style="margin-bottom: 16px;" *ngIf="isAdmin && adminMode === 'group'">
                        <app-field-select
                            [label]="'dashboard_select_top_bottom_label' | translate" 
                            [model]="select_top_bottom" 
                            [options]="options.select_top_bottom"
                            (modelChange)="selectTopBottom($event)"
                        ></app-field-select>
                    </div>
                    <!-- ADMIN DEALER -->
                    <div style="margin-bottom: 16px;" *ngIf="isAdmin && adminMode === 'dealer'">
                        <app-field-typeahead 
                            (modelChange)="selectDealer($event);" 
                            [options]="options.dealer"
                            [searchValueARR]="['title']"
                            [afterARR]="[]"
                            [afterInputARR]="[]"
                            [editable]="false"
                            [label]="'dashboard_select_dealer' | translate"
                            [placeholder]="'dashboard_select_dealer_placeholder' | translate">
                        </app-field-typeahead>
                    </div>
                    <!-- DEFAULT SEARCH -->
                    <div class="d-flex align-items-end flex-wrap">
                        <app-field-select
                            [label]="'dashboard_period' | translate" 
                            [model]="period" 
                            [options]="options.date_range"
                            (modelChange)="period$.next($event);"
                        ></app-field-select>
                        <div class="d-flex align-items-end flex-wrap">
                            <ng-container *ngIf="period === 'date_range'">
                                <span class="sep">{{'dashboard_sep_from' | translate}}</span>
                                <app-field-date 
                                    [model]="SEARCH.start_date" 
                                    (modelChange)="SEARCH.start_date = $event;"
                                    (changed)="search();"
                                    [placeholder]="'misc_date_placeholder' | translate"
                                    [positionDateSelector]="'bottom'"
                                    [disableSince]="disableSinceStart"
                                    [disableUntil]="disableUntilStart"
                                ></app-field-date>
                                <span class="sep">{{'dashboard_sep_until' | translate}}</span>
                                <app-field-date 
                                    [model]="SEARCH.end_date" 
                                    (modelChange)="SEARCH.end_date = $event;"
                                    (changed)="search();"
                                    [placeholder]="'misc_date_placeholder' | translate"
                                    [positionDateSelector]="'bottom'"
                                    [disableUntil]="disableUntilEnd"
                                    [disableSince]="disableSinceEnd"
                                ></app-field-date>
                            </ng-container>
                        </div>
                    </div>
                </div>
                
                <!-- results -->
                <ng-container *ngIf="adminMode !== 'dealer' || (adminMode === 'dealer' && select_dealer)">
                    <!-- ADMIN GROUP -->
                    <div class="numbers-wrap panel" *ngIf="(isAdmin && adminMode !== 'dealer') && (!adminModal === 'dealer' || !loading)">
                        <span>{{'dashboard_amount_of_surveys' | translate}} <span>{{STATS.info.totalCount || 0}}</span></span>
                        <span>{{'dashboard_amount_of_surveys_completed' | translate}} <span>{{STATS.info.completedCount || 0}}</span></span>
                    </div>
                    <!-- DEALER | ADMIN DEALER -->
                    <div class="numbers-wrap panel" *ngIf="(!isAdmin || adminMode === 'dealer') && (!adminModal === 'dealer' || !loading)">
                        <span>{{'dashboard_amount_of_surveys' | translate}} <span>{{STATS.totalCount || 0}}</span></span>
                        <span>{{'dashboard_amount_of_surveys_completed' | translate}} <span>{{STATS.completedCount || 0}}</span></span>
                    </div>
                    <ng-container *ngIf="loading" >
                        <div style="margin-top: 32px;"><app-spinner [centerVertically]="false"></app-spinner></div>
                    </ng-container>
                    <div class="data-wrap" *ngIf="!loading">
                        <!-- data item allQuestionsData -->
                        <div class="data-item data-item--chart panel" *ngIf="allQuestionsData?.datasets[0]?.data?.length && (!isAdmin || adminMode === 'dealer')">
                            <!-- title -->
                            <div class="data-title" style="margin-bottom: -6px;">{{'dashboard_all_questions_title' | translate}}</div>
                            <!-- chart -->
                            <div class="data-chart">
                                <app-horizontal-bar-chart
                                    [datasets]="allQuestionsData.datasets"
                                    [labels]="allQuestionsData.labels"
                                    [options]="allQuestionsData.options">
                                </app-horizontal-bar-chart>
                            </div>
                        </div>
                        <ng-container *ngFor="let item of ratingQuestionsData">
                            <!-- DEALER | ADMIN DEALER -->
                            <ng-container *ngIf="!isAdmin || adminMode === 'dealer'">
                                <!-- data item -->
                                <div class="data-item data-item--rating panel">
                                    <!-- title -->
                                    <div class="data-title">{{item.title}}</div>
                                    <!-- chart -->
                                    <div class="data-chart" *ngIf="item?.chart">
                                        <app-line-chart
                                            [datasets]="item.chart.datasets"
                                            [labels]="item.chart.labels"
                                            [options]="item.chart.options">
                                        </app-line-chart>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- ADMIN GROUP -->
                            <ng-container *ngIf="isAdmin && adminMode === 'group'">
                                <!-- data item -->
                                <div class="data-item data-item--rating panel">
                                    <!-- title -->
                                    <div class="data-title">{{item.title}}</div>
                                    <!-- chart -->
                                    <div class="data-chart" *ngIf="item?.chart">
                                        <app-combined-vertical-bar-chart
                                            [datasets]="item.chart.datasets"
                                            [mean]="item.chart.mean"
                                            [labels]="item.chart.labels"
                                            [options]="item.chart.options">
                                        </app-combined-vertical-bar-chart>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <!-- ADMIN GROUP NO TEXT QUESTIONS DATA -->
                        <ng-container *ngFor="let item of textQuestionsData">
                            <!-- DEALER | ADMIN DEALER -->
                            <ng-container *ngIf="!isAdmin || adminMode === 'dealer'">
                                <!-- data item -->
                                <div class="data-item data-item--text panel">
                                    <!-- title -->
                                    <div class="data-title">{{item.title}}</div>
                                    <!-- text -->
                                    <div class="data-text">
                                        <ng-container *ngFor="let comment of item.comments">
                                            <div class="comment">
                                                "{{comment.text.a}}"
                                                <div class="plate">{{comment.text.lp}}</div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!item.comments?.length">
                                            <div class="empty-state">{{'dashboard_no_results' | translate}}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>

            </div>
        </ng-container>
    </div>
</div>