<div class="container page" *ngIf="!translationsLoading">
    <div class="speech-bubble">
        <h1>
            <span>{{'login_title_1' | translate}}</span><br>
            <span class="secondary-color">{{'login_title_2' | translate}}</span>
        </h1>
        <div class="title-sep"></div>
        <div class="descr">{{'login_pincode_label' | translate}}</div>
        <div class="form-wrap">
            <app-field-text 
                [model]="pin" 
                (modelChange)="pin = $event;"
                [error]="validation.pin"
                code="pin"
                (enter)="pin && login()"
                [instant]="true"
                placeholder="">
            </app-field-text>
            <button [class.disabled]="!pin || saving" (click)="pin && login()" class="button default">{{'login_continue' | translate}}</button>
        </div>
    </div>
</div>