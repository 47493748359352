<div class="container page" *ngIf="!translationsLoading">
    <div class="speech-bubble">
        <h2>{{'survey_new' | translate}}</h2>
        <div class="descr descr-1">{{'survey_descr' | translate}}</div>
        <div class="form-wrap">
            <app-field-text 
                [model]="FORM.licensePlate" 
                (modelChange)="FORM.licensePlate = $event;"
                [error]="validation.licensePlate"
                (enter)="canSubmit() && submit()"
                [instant]="true"
                [autofocus]="true"
                [caps]="true"
                [placeholder]="'survey_license_plate_placeholder' | translate">
            </app-field-text>
            <ng-container *ngIf="sendSms">
                <app-field-text 
                    [model]="FORM.phone" 
                    (modelChange)="FORM.phone = $event;"
                    [error]="validation.phone"
                    (enter)="FORM.phone && newSurvey()"
                    [instant]="true"
                    mask="00 000 00 00 00"
                    [autofocus]="true"
                    type="tel"
                    [placeholder]="'survey_license_plate_placeholder' | translate">
                </app-field-text>
                <app-field-horizontal-radio 
                    [model]="FORM.language" 
                    (modelChange)="FORM.language = $event"
                    [error]="validation.language"
                    [options]="languageOptions"
                    [optionWidth]="'50%'">
                </app-field-horizontal-radio>
            </ng-container>
            <button [class.disabled]="!canSubmit()" (click)="canSubmit() && submit()" class="button default">{{(sendSms ? 'survey_send_sms' : 'survey_start') | translate}}</button>
        </div>
        <div class="descr descr-2">{{'survey_or' | translate}}</div>
        <ng-container *ngIf="!sendSms">
            <div class="descr descr-3" (click)="openSendSms()">{{'survey_open_send_sms' | translate}}</div>
        </ng-container>
        <ng-container *ngIf="sendSms">
            <div class="descr descr-3" (click)="closeSendSms()">{{'survey_close_send_sms' | translate}}</div>
        </ng-container>
    </div>
</div>