import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-field-horizontal-radio',
    templateUrl: './field-horizontal-radio.component.html',
    styleUrls: ['./field-horizontal-radio.component.scss']
})
export class FieldHorizontalRadioComponent implements OnInit {
    @Input() label?: string;
    @Input() error?: string;
    @Input() options: [{ title: string; value: any }];
    @Input() optionWidth: string;
    @Input() model: any;
    @Input() allowToggle: boolean = false;
    @Input() disabled: boolean = false;
    @Output() modelChange = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    clickOption(option: { title: string; value: any }) {
        if (this.allowToggle && this.model === option.value) {
            this.modelChange.emit(undefined);
            return;
        }
        this.modelChange.emit(option.value);
    }
}
