import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root'
})
export class TranslatorService {
    private translations$ = new BehaviorSubject<Object>({});
    private language$ = new BehaviorSubject<any>(null);
    private ready: boolean = false;
    addedTranslationCodes: string[] = [];

    constructor(
        private DefaultService: DefaultService,
        public Route: ActivatedRoute,
        private ConfigService: ConfigService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    public init(language: any) {
        this.setLanguage(language);
        return this.DefaultService.languageGetAllStrings().pipe(
            tap((next) => {
                this.translations$.next(next);
                this.ready = true;
            })
        );
    }

    public setLanguage(language: any) {
        language = language || 'nl';
        this.document.documentElement.lang = language;
        this.language$.next(language);
        this.ConfigService.setTranslationsLoading(true);
        setTimeout(() => {
            this.ConfigService.setTranslationsLoading(false);
        }, 1);
    }

    public getTranslation(key: string): string {
        if (!key || !this.ready) return key;
        key = key.toLowerCase();
        if (this.translations$.getValue()[this.getLanguageValue()][key]) {
            return this.translations$.getValue()[this.getLanguageValue()][key];
        } else {
            if (this.addedTranslationCodes.indexOf(key) == -1) {
                this.DefaultService.languageAddNewString({ code: key }).subscribe();
                this.addedTranslationCodes.push(key);
            }
            return key;
        }
    }

    public getTranslations(): Observable<Object> {
        return this.translations$.asObservable();
    }

    public getLanguage(): Observable<any> {
        return this.language$.asObservable();
    }

    public getLanguageValue(): any {
        return this.language$.getValue();
    }
}
