import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SurveyService } from 'src/app/services/survey.service';
import { TranslatorService } from 'src/app/services/translator.service';
import { TranslatePipe } from 'src/app/utils/pipes';

@Component({
    selector: 'app-survey-detail',
    templateUrl: './survey-detail.component.html',
    styleUrls: ['./survey-detail.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({
                    transform: 'translateY(60%)',
                    opacity: 0
                }),
                animate(
                    '500ms ease',
                    style({
                        transform: 'translateY(0%)',
                        opacity: 1
                    })
                )
            ]),
            transition(':leave', [
                style({
                    transform: 'translateY(0%)',
                    opacity: 1,
                    position: 'absolute',
                    top: 0,
                    bottom: 0
                }),
                animate(
                    '300ms ease',
                    style({
                        opacity: 0,
                        transform: 'translateY(-30%)'
                    })
                )
            ])
        ])
    ]
})
export class SurveyDetailComponent implements OnInit {
    animate: boolean = false;
    ready: boolean = false;
    saving: boolean = false;
    completed: boolean = false;
    tinyId: string = '';
    survey: any = null;
    language: string = null;
    activeIndex: number = 0;
    user: any = null;

    constructor(
        private SurveyService: SurveyService,
        private Route: ActivatedRoute,
        private TranslatorService: TranslatorService,
        private AuthenticationService: AuthenticationService,
        private ToastrService: ToastrService,
        private TranslatePipe: TranslatePipe
    ) {
        this.TranslatorService.getLanguage().subscribe((next) => {
            this.language = next;
        });
        this.AuthenticationService.getUser().subscribe((next) => {
            this.user = next;
        });
    }

    ngOnInit(): void {
        this.tinyId = this.Route.snapshot?.params?.tinyId;
        this.SurveyService.getSurvey(this.tinyId).subscribe((next) => {
            this.ready = true;
            this.survey = next;
            setTimeout(() => {
                this.animate = true;
            }, 1);
        });
    }

    next() {
        if (this.activeIndex == this.survey.questions.length - 1) {
            this.submit();
        } else this.activeIndex++;
    }

    submit() {
        this.saving = true;
        this.SurveyService.submitSurvey(this.survey, this.survey.id).subscribe({
            next: (next) => {
                this.completed = true;
                this.saving = false;
            },
            error: (error) => {
                this.ToastrService.error(this.TranslatePipe.transform('toast_validation_error'));
            }
        });
    }
}
