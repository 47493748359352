import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    user: any = {};
    translationsLoading: boolean = false;

    constructor(
        public AuthenticationService: AuthenticationService,
        private ConfigService: ConfigService
    ) {
        this.AuthenticationService.getUser().subscribe((next) => {
            this.user = next;
        });
    }

    ngOnInit(): void {
        this.ConfigService.getTranslationsLoading().subscribe((next) => {
            this.translationsLoading = next;
        });
    }
}
