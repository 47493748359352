import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { ResourceService } from './resource.service';
import { TranslatorService } from './translator.service';

@Injectable({
    providedIn: 'root'
})
export class InitService {
    constructor(
        private ResourceService: ResourceService,
        private TranslatorService: TranslatorService,
        public AuthenticationService: AuthenticationService
    ) {}

    initialize() {
        return new Promise<void>((resolve, reject) => {
            resolve();
        });
    }
}
