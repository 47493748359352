import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { TranslatorService } from 'src/app/services/translator.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    translationsLoading: boolean = false;

    constructor(private ConfigService: ConfigService) {}

    ngOnInit(): void {
        this.ConfigService.getTranslationsLoading().subscribe((next) => {
            this.translationsLoading = next;
        });
    }
}
