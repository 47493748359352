import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SurveyService } from 'src/app/services/survey.service';
import { TranslatorService } from 'src/app/services/translator.service';
import { TranslatePipe } from 'src/app/utils/pipes';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'app-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
    FORM: any = {};
    validation: any = {};
    saving: boolean = false;
    sendSms: boolean = false;
    languageOptions: any[] = [];
    translationsLoading: boolean = false;

    constructor(
        private SurveyService: SurveyService,
        private Router: Router,
        private TranslatePipe: TranslatePipe,
        private TranslatorService: TranslatorService,
        private toastr: ToastrService,
        private ConfigService: ConfigService
    ) {}

    ngOnInit(): void {
        this.ConfigService.getTranslationsLoading().subscribe((next) => {
            this.translationsLoading = next;
        });
        this.TranslatorService.getLanguage().subscribe((next) => {
            this.setLanguageOptions();
        });
    }

    submit() {
        this.saving = true;
        const FORM = JSON.parse(JSON.stringify(this.FORM));
        if (FORM.phone) FORM.phone = `${FORM.phone}`;
        this.SurveyService.newSurvey(FORM).subscribe({
            next: (next) => {
                setTimeout(() => {
                    if (!this.FORM.phone) {
                        this.Router.navigate(['/', next.tinySurveyId]);
                    } else {
                        this.Router.navigate(['/']);
                        this.toastr.success(this.TranslatePipe.transform('toast_sms_sent'));
                    }
                }, 200);
            },
            error: (error) => {
                this.validation = error.error.details;
                this.saving = false;
            }
        });
    }

    canSubmit() {
        if (this.sendSms) {
            return this.FORM.licensePlate && !this.saving && this.FORM.phone && this.FORM.language;
        }
        return this.FORM.licensePlate && !this.saving;
    }

    openSendSms() {
        this.sendSms = true;
        this.FORM.phone = '+32';
        this.FORM.language = this.TranslatorService.getLanguageValue();
    }

    closeSendSms() {
        this.sendSms = false;
        this.FORM.phone = null;
        this.FORM.language = null;
    }

    setLanguageOptions() {
        this.languageOptions = [
            { title: this.TranslatePipe.transform('lang_nl'), value: 'nl' },
            { title: this.TranslatePipe.transform('lang_fr'), value: 'fr' }
        ];
    }
}
