<div class="container page" *ngIf="!translationsLoading">
    <div class="speech-bubble">
        <h1>
            <span class="secondary-color">{{'home_title_1' | translate}}</span><br>
            <span>{{user.name}}</span>
        </h1>
        <div class="title-sep secondary-background"></div>
        <div class="descr">{{'home_descr' | translate}}</div>
        <div class="tiles-wrap">
            <a [routerLink]="['/survey']" class="tile">
                <img src="/assets/img/icons/survey.svg" alt="survey">
                <span>{{'home_tile_survey' | translate}}</span>
            </a>
            <a [routerLink]="['/dashboard']" class="tile">
                <img src="/assets/img/icons/dashboard.svg" alt="dashboard">
                <span>{{'home_tile_dashboard' | translate}}</span>
            </a>
        </div>
    </div>
</div>