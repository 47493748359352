import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Point } from 'chart.js';
import Chart from 'chart.js/auto';

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
    @ViewChild('chart')
    private chartRef: ElementRef;
    private chart: Chart;
    private data: Point[];

    @Input() datasets: any = null;
    @Input() labels: any = null;
    @Input() options: any = null;

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.chart = new Chart(this.chartRef.nativeElement, {
            type: 'line',
            data: { datasets: this.datasets, labels: this.labels },
            options: this.options
        });
    }
}
