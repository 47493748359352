import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { TranslatorService } from './translator.service';

@Injectable({
    providedIn: 'root'
})
export class SurveyService {
    constructor(private DefaultService: DefaultService, private TranslatorService: TranslatorService) {}

    public newSurvey(FORM: any) {
        return this.DefaultService.surveyCreateNew(FORM);
    }

    public getSurvey(tinyId) {
        return this.DefaultService.surveyGetInfo(tinyId).pipe(
            tap((next) => {
                this.TranslatorService.setLanguage(next.language);
            })
        );
    }

    public submitSurvey(FORM, id) {
        return this.DefaultService.surveySubmit(FORM, id);
    }
}
