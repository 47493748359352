
<div class="field field-field-horizontal-radio">
    <span *ngIf="label" class="label">{{label}} <span *ngIf="optionalString">({{optionalString}})</span></span>
    <div [class.disabled-wrap]="disabled">
        <div class="d-flex flex-row options" [class.w-100]="!optionWidth">
            <div [ngStyle]="{'width': optionWidth || 'auto'}" *ngFor="let option of options;" (click)="clickOption(option)" [class.active]="option.value === model" class="option flex-grow-1 d-flex align-items-center justify-content-center">
                {{option.title}}
            </div>
        </div>
    </div>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>