import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private user$ = new BehaviorSubject<object>(null);

    constructor(private DefaultService: DefaultService) {}

    public login(pin: any) {
        if (pin) pin = pin.toString();
        return this.DefaultService.userDealerLogin({ pin: pin }).pipe(tap((next) => {}));
    }

    public fetchUser(): any {
        return this.DefaultService.userMe().pipe(
            tap((next) => {
                this.setUser(next);
            })
        );
    }

    public logout() {
        this.DefaultService.userLogout().subscribe((next) => {
            window.location.href = '/login';
        });
    }

    public onUnauth() {
        if (window.location.pathname.indexOf('/login') == -1) {
            window.location.href = '/login';
        }
    }

    public getUser(): Observable<object> {
        return this.user$.asObservable();
    }

    public getUserValue() {
        return this.user$.getValue();
    }

    private setUser(user: object) {
        this.user$.next(user);
    }
}
